import React from 'react';
import MenuYannickImg from '../../assets/Cocktails_Yannick_Ouellet.jpg';

const MenuYannick = () => {
return (
        <img
        src={MenuYannickImg}
        alt={'Menu_Yannick_Ouellet'}
        style={{ width: '100vw', height: 'auto' }}
        />
    );
}

export default MenuYannick;